





















































































































































































































































































































































.addClassCtr {
  .el-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .textBt {
      width: 100%;
      margin-bottom: 20px;
    }

    .form-item {
      width: 45%;
    }

    .form-item_1 {
      width: 100%;
    }
  }

  .bottom-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .upload-demo {
    .el-upload {
      border: none !important;
      max-width: 5rem !important;
      height: 30px !important;
    }
  }
}
