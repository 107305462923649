.addClassCtr .el-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.addClassCtr .el-form .textBt {
  width: 100%;
  margin-bottom: 20px;
}
.addClassCtr .el-form .form-item {
  width: 45%;
}
.addClassCtr .el-form .form-item_1 {
  width: 100%;
}
.addClassCtr .bottom-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addClassCtr .upload-demo .el-upload {
  border: none !important;
  max-width: 5rem !important;
  height: 30px !important;
}
